import ValoresImg from 'common/assets/valores.png'
import MissaoImg from 'common/assets/missao.png'
import VisaoImg from 'common/assets/visao.png'

export const infoList = [
  {
    image: MissaoImg,
    alt: 'missao-imagem',
    title: 'Missão',
    text: 'Atender toda a população Maricaense com serviços de transporte e mobilidade, fazendo com que a mesma venha a fazer parte do dia a dia da população de Maricaense.',
  },
  {
    image: ValoresImg,
    alt: 'valores-imagem',
    title: 'Valores',
    text: 'Queremos fazer o melhor para nossos usuários. Buscamos, comprometimento, foco, proatividade, responsabilidade, eficiência, crescimento, Conforto e segurança em primeiro lugar.',
  },
  {
    image: VisaoImg,
    alt: 'visao-imagem',
    title: 'Visão',
    text: 'Atendimento de excelência para a população de Maricaense, buscando ser a principal empresa de transporte e mobilidade de Maricá, mas atuante  recomendado da Cidade.',
  },
]
